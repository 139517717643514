import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Header from "./components/Header";
import TopPage from "./components/TopPage";
import LeadPage from "./components/LeadPage";
import CreateBookPage from "./components/CreateBookPage";
import ChangeBookPage from "./components/ChangeBookPage";
import ClientPage from "./components/ClientPage";
import FixedAssetsSchedule from "./components/FixedAssetsSchedule";
import Loading from "./components/Loading";
import FailedtoLoad from "./components/FailedtoLoad";

// context
const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path={`/`} element={<TopPage />} />
        <Route path={`/client`} element={<ClientPage />} />
        <Route path={`/create_book`} element={<CreateBookPage />} />
        <Route path={`/change_book`} element={<ChangeBookPage />} />
        <Route path={`/lead`} element={<LeadPage />} />
        <Route path={`/faa`} element={<FixedAssetsSchedule />} />
        <Route path={`/Loading`} element={<Loading />} />
        <Route path={`/FailedtoLoad`} element={<FailedtoLoad />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
