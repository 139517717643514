import React from 'react'

function FailedtoLoad() {
    return (
        <div className="alert alert-danger m-5" role="alert">
            Filed to load data.
        </div>
    )
}

export default FailedtoLoad