import React from "react";
import Loading from "./Loading";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { Accordion, Button, Card, Table, Modal, FloatingLabel, Form } from "react-bootstrap";

const AwpPage = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [showModa1, setShowModa1] = React.useState(false);
  const [showModa2, setShowModa2] = React.useState(false);
  const [awpData, setAwpData] = React.useState();
  const [clientCode, setClientCode] = React.useState("");
  const [accountingPeriod, setAccountingPeriod] = React.useState("");
  const [isLoading, setIsLoading] = React.useState("");
  const moda1Close = () => setShowModa1(false);
  const moda1Show = () => setShowModa1(true);
  const moda2Close = () => setShowModa2(false);
  const moda2Show = () => setShowModa2(true);

  React.useEffect(() => {
    if (instance) {
      get_awp(instance, accounts, "awp");
    }
  }, [instance, accounts]);

  const get_awp = (instance, accounts, action) => {
    setIsLoading(true);
    const tokenRequest = {
      scopes: ["User.Read"], // replace with your API's scope
      account: accounts[0], // provide the account object
    };
    instance.acquireTokenSilent(tokenRequest).then((response) => {
      const url = `${process.env.REACT_APP_APIENDPOINT}?action=${action}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: response.accessToken,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          data.forEach(item => {
            item.awp = JSON.parse(item.awp);
          });
          setAwpData(data);
          setIsLoading(false);
        });
    }
    );
  };

  const addClient = () => {
    const tokenRequest = {
      scopes: ["User.Read"], // replace with your API's scope
      account: accounts[0], // provide the account object
    };
    const postData = [{ "client": [{ client_code: clientCode }] }];
    instance.acquireTokenSilent(tokenRequest).then((response) => {
      const url = `${process.env.REACT_APP_APIENDPOINT}`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: response.accessToken,
        },
        body: JSON.stringify(postData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
          };
        })
        .then((data) => {
          alert("登録しました。");
          get_awp(instance, accounts, "awp");
          moda2Close();
        })
        .catch((error) =>
          alert("登録に失敗しました。既に登録されている可能性があります。")
        );
    }
    );
  }
  const addAccountingPeriod = () => {
    const tokenRequest = {
      scopes: ["User.Read"], // replace with your API's scope
      account: accounts[0], // provide the account object
    };
    const postData = [{ "awp": [{ id: clientCode + accountingPeriod, client_code: clientCode, accounting_period: accountingPeriod }] }];
    instance.acquireTokenSilent(tokenRequest).then((response) => {
      const url = `${process.env.REACT_APP_APIENDPOINT}`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: response.accessToken,
        },
        body: JSON.stringify(postData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
          };
        })
        .then((data) => {
          alert("登録しました。");
          get_awp(instance, accounts, "awp");
          moda2Close();
        })
        .catch((error) =>
          alert("登録に失敗しました。既に登録されている可能性があります。")
        );
    }
    );
  }

  if (isLoading) {
    return <Loading />; // ローディング中はLoadingコンポーネントを表示します。
  }

  return (
    <>
      <Card className="m-3 shadow-sm mx-5">
        <Card.Header>監査調書一覧</Card.Header>
        <Card.Body>
          <button className="btn btn-primary mb-2" onClick={moda1Show}>クライアント登録</button>
          <Accordion defaultActiveKey="0">
            {awpData && awpData.map((row, rowindex) => (
              <Accordion.Item eventKey={row.client_code}>
                <Accordion.Header>{row.client_code}</Accordion.Header>
                <Accordion.Body>
                  {row.awp && row.awp.map((awp, awpindex) => (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey={awp.id}>
                        <Accordion.Header>{awp.id}</Accordion.Header>
                        <Accordion.Body>
                          <Table bordered hover>
                            <thead>
                              <tr key={awp.id}>
                                <th>調書</th>
                                <th>アーカイブ日</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {awp.audit_procedures && awp.audit_procedures.map((ap, apindex) => (
                                <tr>
                                  <td>{ap.id}</td>
                                  <td>{ap.archive_date}</td>
                                  <td>
                                    <Button variant="primary rounded-pill me-2" size="sm" disabled={!(ap.edit_permissions && ap.edit_permissions.some(ep => ep.email === accounts[0].username))} onClick={() => navigate('/lead', { state: { ap_id: ap.id } })}>選択</Button>
                                    <Button variant="success rounded-pill me-2" size="sm" disabled={!(ap.edit_permissions && ap.edit_permissions.some(ep => ep.email === accounts[0].username))}>編集</Button>
                                    <Button variant="danger rounded-pill" size="sm" disabled={!(ap.edit_permissions && ap.edit_permissions.some(ep => ep.email === accounts[0].username))}>削除</Button></td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td><Button size="sm" onClick={() => navigate('create_book')}>追加</Button></td>
                              </tr>
                            </tfoot>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}
                  <Button variant="outline-primary mt-2" size="sm" onClick={() => { setClientCode(row.client_code); moda2Show() }}>年度の追加</Button>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Card.Body>
      </Card>
      {/* add_client_modal */}
      <Modal show={showModa1} onHide={moda1Close}>
        <Modal.Header closeButton>
          <Modal.Title>クライアント追加</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel
            controlId="floatingInput"
            label="クライアントコード"
            className="mb-3"
          >
            <Form.Control type="text" placeholder="" value={clientCode} onChange={(e) => setClientCode((e.target.value).toUpperCase())} />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={moda1Close}>
            キャンセル
          </Button>
          {/* クライアントコードがnull又は空白の場合は無効にする */}
          <Button variant="primary"
            onClick={addClient}
            disabled={clientCode === null || clientCode === ""}>
            登録
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add_accountingPeriod_modal */}
      <Modal show={showModa2} onHide={moda2Close}>
        <Modal.Header closeButton>
          <Modal.Title>会計年度追加</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel
            controlId="floatingInput"
            label="クライアントコード"
            className="mb-3"
          >
            <Form.Control type="text" placeholder="" value={clientCode} />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="会計期間"
            className="mb-3"
          >
            <Form.Control type="number" placeholder="" value={accountingPeriod} onChange={(e) => setAccountingPeriod(e.target.value)} />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={moda2Close}>
            キャンセル
          </Button>
          {/* クライアントコードがnull又は空白の場合は無効にする */}
          <Button variant="primary"
            onClick={addAccountingPeriod}
            disabled={clientCode === null || clientCode === "" || accountingPeriod === null || accountingPeriod === ""}>
            登録
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


export default AwpPage;