import React from "react";
import { useMsal } from "@azure/msal-react";

const FixedAssetsSchedule = ({ setCurrentApp }) => {
  // アクセストークン
  const { instance, accounts } = useMsal();
  //   API_GET(instance, accounts);

  // テーブルデータ
  const [tableData, setTableData] = React.useState([
    {
      accountName: "建物",
      beginningBalance: 1000000,
      acquisition: 100000,
      otherIncrease: 0,
      depreciation: 0,
      retirement: 0,
      sale: 0,
      impairment: 0,
      otherDecrease: 0,
      endingBalance: 1100000,
    },
    {
      accountName: "建物減価償却累計額",
      beginningBalance: -100000,
      acquisition: 0,
      otherIncrease: 0,
      depreciation: 0,
      retirement: -50000,
      sale: 0,
      impairment: 0,
      otherDecrease: 0,
      endingBalance: -150000,
    },
  ]);

  const changeRow = (rowIndex, col, value) => {
    const newData = [...tableData];
    if (col === "accountName" || value === "-") {
      newData[rowIndex][col] = value;
    } else {
      newData[rowIndex][col] = Number(value.replace(/,/g, ""));
      const total =
        newData[rowIndex]["beginningBalance"] +
        newData[rowIndex]["acquisition"] +
        newData[rowIndex]["otherIncrease"] +
        newData[rowIndex]["depreciation"] +
        newData[rowIndex]["retirement"] +
        newData[rowIndex]["sale"] +
        newData[rowIndex]["impairment"] +
        newData[rowIndex]["otherDecrease"];
      newData[rowIndex]["endingBalance"] = Number(total);
    }
    setTableData(newData);
  };

  const conlusion = {
    実施者: "担当者",
    実施日: "",
    実施者コメント: "問題ない",
    査閲者: "査閲者",
    査閲日: "2021-01-02",
    査閲者コメント: "問題ない",
  };

  return (
    <div>
      <div className="card m-3 shadow-sm mx-5">
        <div className="card-header">固定資産増減明細</div>
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center" rowSpan={2}>
                  科目名
                </th>
                <th className="text-center" rowSpan={2}>
                  期首残高
                </th>
                <th className="text-center" colSpan={2}>
                  当期増加額
                </th>
                <th className="text-center" colSpan={5}>
                  当期減少額
                </th>
                <th className="text-center" rowSpan={2}>
                  期末残高
                </th>
              </tr>
              <tr>
                <th className="text-center">取得</th>
                <th className="text-center">その他</th>
                <th className="text-center">減価償却費</th>
                <th className="text-center">除却</th>
                <th className="text-center">売却</th>
                <th className="text-center">減損</th>
                <th className="text-center">その他</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, rowIndex) => (
                <tr>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={row.accountName}
                      onChange={(e) => changeRow(rowIndex, "accountName", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.beginningBalance.toLocaleString()}
                      onChange={(e) => changeRow(rowIndex, "beginningBalance", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.acquisition.toLocaleString()}
                      onChange={(e) => changeRow(rowIndex, "acquisition", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.otherIncrease.toLocaleString()}
                      onChange={(e) => changeRow(rowIndex, "otherIncrease", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.depreciation.toLocaleString()}
                      onChange={(e) => changeRow(rowIndex, "depreciation", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.retirement.toLocaleString()}
                      onChange={(e) => changeRow(rowIndex, "retirement", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.sale.toLocaleString()}
                      onChange={(e) => changeRow(rowIndex, "sale", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.impairment.toLocaleString()}
                      onChange={(e) => changeRow(rowIndex, "impairment", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.otherDecrease.toLocaleString()}
                      onChange={(e) => changeRow(rowIndex, "otherDecrease", e.target.value)}
                    />
                  </td>
                  <td>{row.endingBalance.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>コメント</p>
          <textarea className="form-control" cols="15" rows="5"></textarea>
        </div>
      </div>
      <div className="card m-3 shadow-sm mx-5">
        <div className="card-header">結論</div>
        <div className="card-body">
          <div className="m-3" id="conclusionForm">
            <div className="row justify-content-start">
              <div className="col input-group">
                <span className="input-group-text">実　施　者</span>
                <input className="form-control" autocomplete="on" list="user-list" name="担当者" value={conlusion.実施者} />
              </div>
              <div className="col input-group">
                <span className="input-group-text">実施日</span>
                <input type="date" className="form-control" name="実施日" value={conlusion.実施日} />
              </div>
              <div className="col input-group">
                <span className="input-group-text">結論</span>
                <input type="text" className="form-control" name="担当者コメント" value={conlusion.実施者コメント} />
              </div>
            </div>
            <div className="row justify-content-start my-3">
              <div className="col input-group">
                <span className="input-group-text">査　閲　者</span>
                <input className="form-control" autocomplete="on" list="user-list" name="査閲者" value={conlusion.査閲者} />
              </div>
              <div className="col input-group">
                <span className="input-group-text">査閲日</span>
                <input type="date" className="form-control" name="査閲日" value={conlusion.査閲日} />
              </div>
              <div className="col input-group">
                <span className="input-group-text">結論</span>
                <input type="text" className="form-control" name="査閲者コメント" value={conlusion.査閲者コメント} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center w-100">
        <button className="col-1 btn btn-primary btn-sm rounded-pill mb-3 mx-1">
          完了
        </button>
      </div>

    </div>
  );
};

function API_GET(instance, accounts) {
  const tokenRequest = {
    scopes: ["User.Read"], // replace with your API's scope
    account: accounts[0], // provide the account object
  };
  instance.acquireTokenSilent(tokenRequest).then((response) => {
    console.log(response.accessToken);
    const id = "MDM20231";
    const table = "clietnList";
    return;
    const url = "/php/API.php" + "?id=" + id + "&table=" + table;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: response.accessToken,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export default FixedAssetsSchedule;
