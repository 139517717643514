import React from "react";

const TopPage = () => {

  return (
    <>
      {/* 審査依頼カード */}
      <div className="card m-3 shadow-sm" >
        <div className="card-header">査閲依頼</div>
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>調書</th>
                <th>実施者</th>
                <th>実施日</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>TDH2023Q4</td>
                <td>山田太郎</td>
                <td>2021-06-30</td>
                <td>
                  <button className="btn btn-primary btn-sm rounded-pill me-2">選択</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div >
    </>
  );
};

export default TopPage;
