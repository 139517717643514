import React from "react";
import { useMsal } from "@azure/msal-react";

const CreateBookPage = ({ setCurrentApp }) => {
  const { instance, accounts } = useMsal();
  // 監査調書データ
  const [bookData, setBookData] = React.useState({
    clientCode: "",
    accountingPeriod: "",
    quarter: 1,
    closingMonth: 1,
    isSignificantDoubt: 0,
  });
  const changebookData = (value, field) => {
    const newbookData = { ...bookData };
    if (field !== "clientCode") {
      value = Number(value);
    }
    newbookData[field] = value;
    setBookData(newbookData);
  };

  // 構成単位データ
  const [cmpData, setCmpData] = React.useState([]);

  const addCmp = () => {
    const newRow = { componentCode: "", closingMonth: bookData.closingMonth };
    setCmpData([...cmpData, newRow]);
    cmpData.push(newRow);
  };
  const removeCmp = (rowIndex) => {
    const newcmpData = [...cmpData];
    newcmpData.splice(rowIndex, 1);
    setCmpData(newcmpData);
  };
  const changeCmp = (value, field, rowIndex) => {
    const newcmpData = [...cmpData];
    if (field !== "componentCode") {
      value = Number(value);
    }
    newcmpData[rowIndex][field] = value;
    setCmpData(newcmpData);
  };
  // 編集権限データ
  const [allowedEditors, setAllowedEditors] = React.useState([{ username: accounts[0].username }]);
  const addEditor = () => {
    const newEditor = { username: "" };
    setAllowedEditors([...allowedEditors, newEditor]);
  };
  const removeEditor = (rowIndex) => {
    const newEditors = [...allowedEditors];
    newEditors.splice(rowIndex, 1);
    setAllowedEditors(newEditors);
  };
  const changeEditor = (value, field, rowIndex) => {
    const newEditors = [...allowedEditors];
    newEditors[rowIndex][field] = value;
    setAllowedEditors(newEditors);
  };

  // DB登録
  const addData = () => {
    if (bookData.clientCode === "") {
      alert("クライアントコードを入力してください。");
      return;
    }
    if (bookData.accountingPeriod === 0 || bookData.accountingPeriod === "") {
      alert("会計年度を入力してください。");
      return;
    }
    // cmpDataのcomponentCodeを大文字に変換
    for (let i = 0; i < cmpData.length; i++) {
      cmpData[i].componentCode = cmpData[i].componentCode.toUpperCase();
    }
    // cmpDataの空を削除
    for (let i = 0; i < cmpData.length; i++) {
      if (cmpData[i].componentCode === "") {
        cmpData.splice(i, 1);
        i--;
      }
    }
    // cmpDataの重複を削除
    for (let i = 0; i < cmpData.length; i++) {
      for (let j = i + 1; j < cmpData.length; j++) {
        if (cmpData[i].componentCode === cmpData[j].componentCode) {
          cmpData.splice(j, 1);
          j--;
        }
      }
    }
    // allowedEditorsの空を削除
    for (let i = 0; i < allowedEditors.length; i++) {
      if (allowedEditors[i].username === "") {
        allowedEditors.splice(i, 1);
        i--;
      }
    }
    // allowedEditorsの重複を削除
    for (let i = 0; i < allowedEditors.length; i++) {
      for (let j = i + 1; j < allowedEditors.length; j++) {
        if (allowedEditors[i].username === allowedEditors[j].username) {
          allowedEditors.splice(j, 1);
          j--;
        }
      }
    }
    // 親データをcmpDataの先頭に追加。既にある場合は削除して追加
    const index = cmpData.findIndex((cmp) => cmp.componentCode === bookData.clientCode);
    if (index !== -1) {
      cmpData.splice(index, 1);
    }
    cmpData.unshift({ componentCode: bookData.clientCode, closingMonth: bookData.closingMonth });
    const data = {
      bookId: bookData.clientCode + bookData.accountingPeriod + "Q" + bookData.quarter,
      clientCode: bookData.clientCode,
      accountingPeriod: bookData.accountingPeriod,
      quarter: bookData.quarter,
      closingMonth: bookData.closingMonth,
      allowedEditors: allowedEditors,
      isSignificantDoubt: bookData.isSignificantDoubt,
      cmpData: cmpData,
    };
    console.log(data);
    function API_POST(instance, accounts, table) {
      const tokenRequest = {
        scopes: ["User.Read"], // replace with your API's scope
        account: accounts[0], // provide the account object
      };
      instance.acquireTokenSilent(tokenRequest).then((response) => {
        // console.log(response.accessToken);
        const url = `${process.env.REACT_APP_APIENDPOINT}?table=${table}`;
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: response.accessToken,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            return response.text();
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
    API_POST(instance, accounts, "clientlist");
    alert("登録しました。");
    // setCurrentApp("TopPage");
  };

  return (
    <div>
      <div className="row justify-content-center w-100">
        <div className="card m-3 w-50 p-0">
          <div className="card-header">監査調書登録</div>
          <div className="card-body">
            <div className="input-group mb-3">
              <span className="input-group-text w-25">クライアントコード</span>
              <input
                type="text"
                className="form-control"
                name="clientCode"
                value={bookData.clientCode}
                onChange={(event) => {
                  const value = event.target.value.toUpperCase();
                  changebookData(value, "clientCode");
                }}
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text w-25">会計年度</span>
              <input
                type="text"
                className="form-control"
                name="accountingPeriod"
                value={bookData.accountingPeriod}
                onChange={(event) => {
                  const value = Number(
                    event.target.value.replace(/[０-９]/g, (s) => {
                      return String.fromCharCode(s.charCodeAt(0) - 65248);
                    })
                  );
                  changebookData(value, "accountingPeriod");
                }}
              />
              <span className="input-group-text">期</span>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text w-25">四半期</span>
              <select
                className="form-control"
                name="quarter"
                value={bookData.quarter}
                onChange={(event) => {
                  const value = Number(event.target.value);
                  changebookData(value, "quarter");
                }}
              >
                <option value="1">Q1</option>
                <option value="2">Q2</option>
                <option value="3">Q3</option>
                <option value="4">Q4</option>
              </select>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text w-25">決算月</span>
              <select
                className="form-control"
                name="closingMonth"
                value={bookData.closingMonth}
                onChange={(event) => {
                  const value = event.target.value;
                  changebookData(value, "closingMonth");
                }}
              >
                {[...Array(12).keys()].map((_, i) => (
                  <option key={i} value={i + 1}>
                    {i + 1}月
                  </option>
                ))}
              </select>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text w-25">重要事象の有無</span>
              <select
                className="form-control"
                name="closingMonth"
                value={bookData.isSignificantDoubt}
                onChange={(event) => {
                  const value = event.target.value;
                  changebookData(value, "isSignificantDoubt");
                }}
              >
                <option value="0">無</option>
                <option value="1">有</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center w-100">
        <div className="card m-3 w-50 p-0">
          <div className="card-header">構成単位</div>
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>構成単位コード</th>
                  <th>決算月</th>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input className="form-control" type="text" value={bookData.clientCode} />
                  </td>
                  <td>
                    <select className="form-control" name="closingMonth" value={bookData.closingMonth}>
                      {[...Array(12).keys()].map((_, i) => (
                        <option key={i} value={i + 1}>
                          {i + 1}月
                        </option>
                      ))}
                    </select>
                  </td>
                  <td></td>
                </tr>
                {cmpData.map((cmp, rowIndex) => (
                  <tr>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={cmp.componentCode}
                        name="componentCode"
                        onChange={(event) => {
                          const value = event.target.value;
                          changeCmp(value, "componentCode", rowIndex);
                        }}
                      />
                    </td>
                    <td>
                      <select
                        className="form-control"
                        name="closingMonth"
                        value={cmp.closingMonth}
                        onChange={(event) => {
                          console.log(event.target.value);
                          const value = event.target.value;
                          changeCmp(value, "componentCode", rowIndex);
                        }}
                      >
                        {[...Array(12).keys()].map((_, i) => (
                          <option key={i} value={i + 1}>
                            {i + 1}月
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <button className="btn btn-danger btn-sm rounded-pill" onClick={() => removeCmp(rowIndex)}>
                        削除
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3">
                    <button className="btn btn-primary btn-sm rounded-pill" onClick={addCmp}>
                      追加
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div className="row justify-content-center w-100">
        <div className="card m-3 w-50 p-0">
          <div className="card-header">閲覧・編集権限リスト</div>
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>メールアドレス</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allowedEditors.map((editor, rowIndex) => (
                  <tr>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={editor.username}
                        name="username"
                        onChange={(event) => {
                          const value = event.target.value;
                          changeEditor(value, "username", rowIndex);
                        }}
                      />
                    </td>
                    <td>
                      {rowIndex !== 0 && (
                        <button className="btn btn-danger btn-sm rounded-pill" onClick={() => removeEditor(rowIndex)}>
                          削除
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2">
                    <button className="btn btn-primary btn-sm rounded-pill" onClick={addEditor}>
                      追加
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div className="row justify-content-center w-100">
        <button className="col-1 btn btn-primary btn-sm rounded-pill mb-3 mx-1" onClick={addData}>
          登録
        </button>
      </div>
    </div>
  );
};

export default CreateBookPage;
