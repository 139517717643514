import React from "react";
import { useLocation } from 'react-router-dom';


const LeadPage = ({ setCurrentApp }) => {
  const location = useLocation();
  console.log(location.state);
  return (
    <div>
      {/* ステップバー */}
      <StepBarCard setCurrentApp={setCurrentApp} />
      {/* リード */}
      <LeadCard setCurrentApp={setCurrentApp} />
      {/* 結論 */}
      <ConclusionCard setCurrentApp={setCurrentApp} />
    </div>
  );
};

const StepBarCard = ({ setCurrentApp }) => {
  const [stepbar, setstepbar] = React.useState([
    {
      name: "リード",
      isCompleted: true,
      onClicked: () => setCurrentApp("LeadPage"),
    },
    { name: "詳細手続", isCompleted: false, navPage: "DetailPage1" },
    { name: "月次推移分析", isCompleted: false, navPage: "DetailPage2" },
    { name: "固定資産増減明細", isCompleted: true, navPage: "DetailPage3" },
    { name: "引当金増減明細", isCompleted: false },
    { name: "貸付金増減明細", isCompleted: false, navPage: "DetailPage5" },
    { name: "借入金増減明細", isCompleted: false, navPage: "DetailPage6" },
  ]);
  return (
    <div>
      {/* ステップバー */}
      <div className="card m-3 shadow-sm">
        <div className="card-header">ステップバー</div>
        <div className="card-body">
          <ol className="step" role="tablist">
            {stepbar.map((row) => (
              <li className={row.isCompleted ? "is-current" : ""} role="button" onClick={() => setCurrentApp(row.navPage)}>
                {row.name}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

const LeadCard = () => {
  return (
    <div>
      {/* リード */}
      <div className="card m-3 shadow-sm">
        <div className="card-header">リード</div>
        <div className="card-body">
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>科目名</th>
                  <th>2023Q1E</th>
                  <th>2023Q2E</th>
                  <th>2023Q3E</th>
                  <th>2023Q4E</th>
                  <th>2023Q4S</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>売上高</td>
                  <td>1000</td>
                  <td>1000</td>
                  <td>1000</td>
                  <td>1000</td>
                  <td>1000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConclusionCard = () => {
  const [AJERJE, setAJERJE] = React.useState([
    {
      借方科目: "現金",
      借方金額: 1000,
      貸方科目: "売上高",
      貸方金額: 1000,
      備考: "テスト",
    },
    {
      借方科目: "現金",
      借方金額: 1000,
      貸方科目: "売上高",
      貸方金額: 1000,
      備考: "テスト",
    },
  ]);

  const addRow = () => {
    const newRow = { 借方科目: "", 借方金額: 0, 貸方科目: "", 貸方金額: 0, 備考: "" };
    setAJERJE([...AJERJE, newRow]);
    AJERJE.push(newRow);
  };

  const deleteRow = (rowIndex) => {
    const confirmed = window.confirm("削除しますか？");
    if (confirmed == false) {
      return;
    }
    const newAJERJE = [...AJERJE];
    newAJERJE.splice(rowIndex, 1);
    setAJERJE(newAJERJE);
  };

  const changeRow = (value, field, rowIndex) => {
    const newAJERJE = [...AJERJE];
    newAJERJE[rowIndex][field] = value;
    setAJERJE(newAJERJE);
  };

  const conlusion = {
    実施者: "担当者",
    実施日: "2021-01-01",
    実施者コメント: "担当者コメント",
    査閲者: "査閲者",
    査閲日: "2021-01-02",
    査閲者コメント: "査閲者コメント",
    審査担当者: "審査担当者",
    審査日: "2021-01-03",
    審査担当者コメント: "審査担当者コメント",
  };

  return (
    <div>
      {/* 結論 */}
      <div className="card m-3 shadow-sm">
        <div className="card-header">結論</div>
        <div className="card-body">
          <div>
            <div className="m-3">
              <label for="exampleFormControlTextarea1" className="form-label">
                <h5>重要事項</h5>
              </label>
              <textarea className="form-control" rows="3"></textarea>
            </div>
            <div className="m-3">
              <label className="form-label">
                <h5>修正仕訳</h5>
              </label>
              <table className="table">
                <thead>
                  <tr>
                    <th>借方科目</th>
                    <th>借方金額</th>
                    <th>貸方科目</th>
                    <th>貸方金額</th>
                    <th colspan="2">備考</th>
                  </tr>
                </thead>
                <tbody>
                  {AJERJE.map((row, rowIndex) => (
                    <tr>
                      <td>
                        <input
                          className="form-control"
                          list="user-list"
                          value={row.借方科目}
                          onChange={(event) => {
                            const value = event.target.value;
                            changeRow(value, "借方科目", rowIndex);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control text-end"
                          list="user-list"
                          // row.借方金額が0の場合は0,空欄の場合は空欄、それ以外はtoLocaleString()でカンマ区切りにする
                          value={row.借方金額 ? Number(row.借方金額).toLocaleString() : ""}
                          onChange={(event) => {
                            const value = parseFloat(event.target.value.replace(/,/g, ""));
                            changeRow(value, "借方金額", rowIndex);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          list="user-list"
                          value={row.貸方科目}
                          onChange={(event) => {
                            const value = event.target.value;
                            changeRow(value, "貸方科目", rowIndex);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control text-end"
                          list="user-list"
                          value={row.貸方金額 ? Number(row.貸方金額).toLocaleString() : ""}
                          onChange={(event) => {
                            const value = parseFloat(event.target.value.replace(/,/g, ""));
                            changeRow(value, "貸方金額", rowIndex);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          list="user-list"
                          value={row.備考}
                          onChange={(event) => {
                            const value = event.target.value;
                            changeRow(value, "備考", rowIndex);
                          }}
                        />
                      </td>
                      <td>
                        <button className="btn btn-sm btn-outline-danger" onClick={() => deleteRow(rowIndex)}>
                          削除
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="6">
                      <button className="btn btn-sm btn-outline-primary" onClick={addRow}>
                        追加
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="m-3" id="conclusionForm">
              <label for="exampleFormControlTextarea1" className="form-label">
                <h5>結論</h5>
              </label>
              <div className="row justify-content-start">
                <div className="col input-group">
                  <span className="input-group-text">実　施　者</span>
                  <input className="form-control" autocomplete="on" list="user-list" name="担当者" value={conlusion.実施者} />
                </div>
                <div className="col input-group">
                  <span className="input-group-text">実施日</span>
                  <input type="date" className="form-control" name="実施日" value={conlusion.実施日} />
                </div>
                <div className="col input-group">
                  <span className="input-group-text">結論</span>
                  <input type="text" className="form-control" name="担当者コメント" value={conlusion.実施者コメント} />
                </div>
              </div>
              <div className="row justify-content-start my-3">
                <div className="col input-group">
                  <span className="input-group-text">査　閲　者</span>
                  <input className="form-control" autocomplete="on" list="user-list" name="査閲者" value={conlusion.査閲者} />
                </div>
                <div className="col input-group">
                  <span className="input-group-text">査閲日</span>
                  <input type="date" className="form-control" name="査閲日" value={conlusion.査閲日} />
                </div>
                <div className="col input-group">
                  <span className="input-group-text">結論</span>
                  <input type="text" className="form-control" name="査閲者コメント" value={conlusion.査閲者コメント} />
                </div>
              </div>
              <div className="row justify-content-start my-3">
                <div className="col input-group">
                  <span className="input-group-text">審査担当者</span>
                  <input className="form-control" autocomplete="on" list="user-list" name="審査担当者" value={conlusion.審査担当者} />
                </div>
                <div className="col input-group">
                  <span className="input-group-text">審査日</span>
                  <input type="date" className="form-control" name="審査日" value={conlusion.審査日} />
                </div>
                <div className="col input-group">
                  <span className="input-group-text">結論</span>
                  <input type="text" className="form-control" name="審査担当者コメント" value={conlusion.審査担当者コメント} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadPage;