import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const headerMenu = [
    {
      name: "計画様式",
      subMenu: [
        {
          name: "様式2",
          onClick: () => navigate(),
        },
        {
          name: "様式3",
          onClick: () => navigate(),
        },
        {
          name: "様式4",
          onClick: () => navigate(),
        },
        {
          name: "様式5",
          onClick: () => navigate(),
        },
        {
          name: "様式6",
          onClick: () => navigate(),
        },
        {
          name: "様式7",
          onClick: () => navigate(),
        },
        {
          name: "様式8",
          onClick: () => navigate(),
        },
        {
          name: "様式9",
          onClick: () => navigate(),
        },
        {
          name: "様式10",
          onClick: () => navigate(),
        },
        {
          name: "様式11",
          onClick: () => navigate(),
        },
      ],
    },
    {
      name: "科目調書",
      subMenu: [
        {
          name: "A現預金",
          onClick: () => navigate(),
        },
        {
          name: "B売上債権",
          onClick: () => navigate(),
        },
        {
          name: "C棚卸資産",
          onClick: () => navigate(),
        },
      ],
    },
    {
      name: "総括",
      subMenu: [
        {
          name: "様式9",
          onClick: () => navigate(),
        },
        {
          name: "PL",
          onClick: () => navigate(),
        },
        {
          name: "CR",
          onClick: () => navigate(),
        },
      ],
    },
    {
      name: "開発用",
      subMenu: [
        {
          name: "Lead",
          link: "/LeadPage",
        },
        {
          name: "調書登録",
          link: "/CreateBookPage",
        },
        {
          name: "調書一覧",
          link: "/client",
        },
        {
          name: "固定資産増減明細",
          link: "/FixedAssetsSchedule",
        },
        {
          name: "読込画面",
          link: "/Loading",
        },
        {
          name: "読込失敗画面",
          link: "/FailedtoLoad",
        },
      ],
    },
  ];
  return (
    <div className="pb-5">
      <nav className="navbar navbar-expand-lg p-0 shadow ms-header fixed-top">
        <div className="container-fluid">
          <div className="navbar">
            <Link className="navbar-brand" style={{ color: "white" }} to="/" role="button">
              Meisei Audit App
            </Link>
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {headerMenu.map((menu, index) => (
                  <li className="nav-item dropdown" key={menu.name}>
                    <Link
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {menu.name}
                    </Link>
                    {menu.subMenu && (
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown" key={index}>
                        {menu.subMenu.map((subMenu, index) => (
                          <li key={subMenu.name}>
                            <Link className="dropdown-item" to={subMenu.link} key={subMenu.name}>
                              {subMenu.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="navbar">
            <button className="btn btn-primary mx-2" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
              テスト
            </button>
            <i className="bi bi-gear mx-1" type="button"></i>
          </div>
        </div>
      </nav >
      {/* モーダル1 */}
      <div className="modal fade" id="exampleModal" tabIndex="-1" >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">...</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
